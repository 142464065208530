<template>
  <div>
    <!-- <div>
      <loading :active.sync="isLoader" loader="spinner" color="#36304a" :width="70"></loading>
    </div>-->

    <div class="animated fadeIn">
      <div id="snackbar"></div>
      <b-card-header class="mb-3">
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Transaction Log
          </b-col>
        </b-row>
      </b-card-header>

      <b-card>
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">From Date :</label>
              <b-form-input type="date" v-model="TransactionFilter.FromDate"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <label for="to-date">To Date :</label>
              <b-form-input type="date" v-model="TransactionFilter.ToDate"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="to-date">Cinema :</label>
              <b-form-select
                v-model="TransactionFilter.Cinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaID"
                :options="$store.getters['common/getAllCinemas']"
              >
                <template slot="first">
                  <option :value="'0'">Select Malls</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="text-right">
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click="getTranscationLog"
            >Search</b-button>
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click.prevent="resetForm"
            >Reset</b-button>
            <b-button
              class="ml-2"
              type="submit"
              size="sm"
              variant="primary"
              @click="ExportTranscationLogExcel"
            >Export to Excel</b-button>
          </b-col>
        </b-row>
        <!-- User Wise Report Setcion Start -->
        <div
          style="width: 100%;height: 100%;position: absolute;background: white;z-index: 1000;"
          v-if="isLoader"
        >
          <CircleLoader />
        </div>
        <v-client-table class="my-2" :data="data" :columns="columns" :options="options"></v-client-table>
        <!-- User Wise Report Setcion End -->
      </b-card>
    </div>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import AccountReportService from "@/services/AccountReportService";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import CircleLoader from "../../Component/SchedulesDetails/Circle.vue";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName, WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "BookingDetailsReport",
  components: {
    Event,
    cSwitch,
    // Loading,
    CircleLoader
  },
  watch: {},
  data: function() {
    return {
      isLoader: false,
      max_char: 256,

      //Book New Transcation
      columns: [
        "Sr",
        "OrderID",
        "CustName",
        "UserEmail",
        "UserMobile",
        "Prefix",
        "PaymentMode",
        "Theatre",
        "ScreenName",
        "MovieName",
        "seatnames",
        "NoOfTickets",
        "Source",
        "ShowDate",
        "TotalAmount",
        "TransactionDate",
        "TransactionStatus",
        "BookStatus"
      ],
      data: [],
      finalArr: [],
      ExcelArr: [],
      options: {
        headings: {
          Sr: "SN.",
          OrderID: "Order ID",
          CustName: "User Name",
          UserEmail: "Email ID",
          UserMobile: "Mobile No.",
          Prefix: "Prefix",
          PaymentMode: "PaymentMode",
          Theatre: "Cinema Name",
          ScreenName: "Screen Name",
          MovieName: "Movie Name",
          seatnames: "Seat Names",
          NoOfTickets: "No Of Tickets",
          Source: "Source",
          ShowDate: "Show Date",
          TotalAmount: "Total Amount",
          TransactionDate: "Transaction Date",
          TransactionStatus: "Payment Status",
          BookStatus: "Book Status"
        },

        sortable: [], //"OrderID","CustName","UserEmail","UserMobile","Theatre","ScreenName","MovieName"
        filterable: [
          "OrderID",
          "CustName",
          "UserEmail",
          "UserMobile",
          "Theatre",
          "ScreenName",
          "MovieName",
          "seatnames"
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      //Book New Transcation
      TransactionFilter: {
        FromDate: "",
        ToDate: "",
        Cinema: 0
      },
      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  beforeMount() {
    this.$store.dispatch("common/setAllCinemas", {});
    this.getFromToDate();
    this.getTranscationLog();
  },
  mounted() {},
  methods: {
    ExportTranscationLogExcel() {
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);

        let ExcelDate = new Date().toDateString();
        if (this.TransactionFilter.FromDate) {
          ExcelDate = new Date(this.TransactionFilter.FromDate).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "Transaction_Log");
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_Transaction_Log_Report_${ExcelDate}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getFromToDate() {
      var today = new Date();
      var firstDay = "";
      var lastDay = "";
      // firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      var firstDay_dd = String(firstDay.getDate()).padStart(2, "0");
      var firstDay_mm = String(firstDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var firstDay_yyyy = firstDay.getFullYear();

      var lastDay_dd = String(lastDay.getDate()).padStart(2, "0");
      var lastDay_mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var lastDay_yyyy = lastDay.getFullYear();

      firstDay = firstDay_yyyy + "-" + firstDay_mm + "-" + firstDay_dd; //2018-06-01
      lastDay = lastDay_yyyy + "-" + lastDay_mm + "-" + lastDay_dd; //2018-06-01

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy; //2018-06-01
      this.FromDate = today;
      this.TransactionFilter.FromDate = firstDay;
      this.TransactionFilter.ToDate = firstDay;
    },
    getTranscationLog() {
      this.data = [];
      this.finalArr = [];
      this.ExcelArr = [];
      this.isLoader = true;
      let payload = {
        view: "test",
        FromDate: this.TransactionFilter.FromDate,
        ToDate: this.TransactionFilter.ToDate,
        CinemaID: this.TransactionFilter.Cinema
      };

      AccountReportService.getTranscationLog(payload)
        .then(response => {
          const { data } = response;
          console.log("Happy Singh=>", data);
          if (data.Status && data.Data.length > 0) {
            this.data = data.Data;
            if (this.data.length > 0) {
              let finalArr = this.finalArr;
              let NewArr = this.data.map(x => {
                finalArr.push({
                  "SN.": x["Sr"],
                  "Order ID": x["OrderID"],
                  "User Name": x["CustName"],
                  "Email ID": x["UserEmail"],
                  "Mobile No.": x["UserMobile"],
                  Prefix: x["Prefix"],
                  PaymentMode: x["PaymentMode"],
                  Cinema: x["Theatre"],
                  Screen: x["ScreenName"],
                  "Movie Name": x["MovieName"],
                  "Seat Names": x["seatnames"],
                  "No Of Tickets": x["NoOfTickets"],
                  Source: x["Source"],
                  "Show Date": x["ShowDate"],
                  "Total Amount": x["TotalAmount"],
                  "Transaction Date": x["TransactionDate"],
                  "Payment Status": x["TransactionStatus"],
                  "Book Status": x["BookStatus"]
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
          this.isLoader = false;
        })
        .catch(error => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm() {
      this.data = [];
      Object.keys(this.TransactionFilter).forEach(
        key => (this.TransactionFilter[key] = "")
      );
      this.TransactionFilter.Cinema = 0;
      this.getFromToDate();
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
label {
  font-weight: 600;
}
.my-custom-scrollbar {
  position: relative;
  height: 550px;
  overflow: auto;
}
.my-custom-scrollbar_xAxis {
  position: relative;
  height: 340px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
